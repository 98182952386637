<template>
  <div role="main" class="main">
    <section>
      <div class="registration-page-content common-page">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                <div class="col-12 text-left page-heading mb-4">
                  <h3 class="m-0">Wedding wishes</h3>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="row">
                <div class="col-12 col-md-5 text-left mb-4"></div>
                <div
                  class="col-12 col-md-7 text-left text-white mb-4"
                  v-if="visitor.full_name"
                >
                  <p class="m-0 text-white wishes-message">{{ message }}</p>
                  <div class="d-flex justify-content-end">
                    <div class="wishes-profile-wrapper text-center">
                      <img
                        :src="visitor.profile_url"
                        class="img-fluid"
                      />
                      <div class="wishes-name mt-2">
                        <small>
                          {{ visitor.full_name }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration-page-footer">
        <div class="footer-wrapper container-fluid">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/DonateWithPaypal.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="goBack()">Return to Program</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl,liveBaseUrl } from "../../constants/config";

export default {
  data() {
    return {
      condolence_id: 0,
      message: "",
      visitor: {
        full_name: "",
        profile_url: null,
      },
    };
  },
  mounted() {
    this.condolence_id = this.$route.params.c_id;
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/condolence/get-info`, {
        condolence_id: this.condolence_id,
      })
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.message = res.detail.message;
          console.log("1111111111111111111",res.detail);
          this.visitor.full_name = res.detail.full_name;
          this.visitor.profile_url = `${apiBaseUrl}/` + res.detail.profile_url;
        } else {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
     connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_order") {
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "start_count") {
          ref.receiveMoveLive(data.order);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    goBack() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.$route.params.e_id+"?x=" + randomnumber);
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
<style>
@font-face {
  font-family: "Fontin-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Regular.woff") format("woff"); 
}
@font-face {
  font-family: "Fontin-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Bold.woff") format("woff");
}
.registration-page-content {
  height: calc(100vh - 135px);
  padding-top: 9rem;
  padding-bottom: 2rem;
  background-image: url("../../assets/img/back-img-2.png") !important;
  background-size: contain;
}
.registration-page-footer {
  position: relative;
  background-image: url("../../assets/img/footer-img.png");
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;
  padding: 15px 0;
  border-image-slice: 1;
  border-top: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(119, 40, 3, 1) 0%,
    rgba(163, 62, 11, 1) 15%,
    rgba(195, 85, 12, 1) 33%,
    rgba(102, 29, 3, 1) 59%,
    rgba(102, 21, 3, 1) 64%,
    rgba(102, 29, 3, 1) 76%,
    rgba(157, 57, 6, 1) 88%
  );
}
.registration-page-footer::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #000000b0;
  position: absolute;
  top: 0;
  left: 0;
}

.page-heading h3 {
  color: #fff;
  font-family: "Fontin-Regular";
}
.page-heading p {
  font-size: 1.5rem;
  line-height: 1.4;
}
.arrow-icon-wrapper {
  height: 24px;
  width: 24px;
}
.avatar-img img {
  height: 150px;
  width: 150px;
  border: 3px solid #fff;
}
.white-border {
  border: 1px solid #fff !important;
  color: #fff;
  background: #000000a0;
}
::placeholder {
  color: #fff !important;
}
.img-avatar-banner {
  display: flex;
  align-items: center;
  
}
.banner-avatar {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  position: relative;
  z-index: 2;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.banner-name span {
  font-weight: 900;
}
.footer-wrapper {
  /* padding: 0 12vw; */
}
.c-column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.wishes-message{
  font-size: 36px !important;
      font-weight: 500 !important;
    line-height: 1.2 !important;
}

.wishes-profile-wrapper img{
  border: 1px solid #fff;
  margin-top: 30px;
}

</style>
